<template>
  <div>
    <div class="view-container">
      <div class="view">
        <div class="view-body">
          <el-menu
              :default-active="activeLink"
              :router="true"
              class="submenu"
              mode="horizontal"
          >
            <el-menu-item
                index="/administration/user"
                v-if="$store.getters['permission/checkPermissions'](['view-user'])"
            >
              {{ $t('system.users') }}
            </el-menu-item>
            <el-menu-item
                index="/administration/role_and_permission"
                v-if="ifHasPermission(['admin'])"
            >
              {{ $t('administration.role_for_projects') }}
            </el-menu-item>
            <el-menu-item
                index="/administration/role"
                v-if="ifHasPermission(['admin'])"
            >
              {{ $t('administration.role_for_system') }}
            </el-menu-item>
            <el-menu-item index="/administration/group">
              {{ $t('administration.groups') }}
            </el-menu-item>
            <!--<el-menu-item index="/administration/enumeration">-->
            <!--{{ $t('administration.enumerations') }}-->
            <!--</el-menu-item>-->
            <!--<el-menu-item index="/administration/import_clients">-->
            <!--{{ $t('administration.import_clients') }}-->
            <!--</el-menu-item>-->
            <el-menu-item
                index="/administration/workflow"
                v-if="ifHasPermission(['admin'])"
            >
              {{ $t('administration.workflow') }}
            </el-menu-item>
            <!--<el-menu-item index="/administration/translate" v-if="ifHasPermission('spain', ['admin'])">-->
            <!--{{ $t('administration.translations') }}-->
            <!--</el-menu-item>-->
            <el-menu-item
                index="/administration/translate-label"
                v-if="ifHasPermission(['admin'])"
            >
              {{ $t('administration.translate_label') }}
            </el-menu-item>
            <el-menu-item
                index="/administration/languages"
                v-if="ifHasPermission(['admin'])"
            >
              {{ $t('administration.languages') }}
            </el-menu-item>
            <el-menu-item
                index="/administration/personal_data"
                v-if="ifHasPermission(['personal-data'])"
            >
              {{ $t('admin.personal') }}
            </el-menu-item>
            <el-menu-item index="/administration/enumeration">
              {{ $t('system_settings.enum') }}
            </el-menu-item>
            <el-menu-item index="/administration/work-orders">
              {{ $t('system_settings.workOrders') }}
            </el-menu-item>
            <el-menu-item index="/administration/configuration">
              {{ $t('system_settings.configuration') }}
            </el-menu-item>
            <el-menu-item index="/administration/json">
              {{ $t('system_settings.json') }}
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'AdministrationMenu',
  data() {
    return {
      activeLink: '/administration/user',
      currentView: {},
      model: {},
      redirect: '/',
      issueModel: {},
    };
  },
  beforeMount() {
    if (this.$route.path.startsWith('/administration/pm_role')) {
      this.activeLink = '/administration/role_and_permission';
    } else if (this.$route.path.startsWith('/administration/group')) {
      this.activeLink = '/administration/group';
    } else if (this.$route.path.startsWith('/administration/languages')) {
      this.activeLink = '/administration/languages';
    } else if (this.$route.path.endsWith('/administration')) {
      this.activeLink = '/administration/user';
    } else if (this.$route.path.startsWith('/administration/user')) {
      this.activeLink = '/administration/user';
    } else {
      this.activeLink = this.$route.path;
    }
  },
  methods: {
    changeActiveLink(link) {
      this.activeLink = link;
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.el-menu-item {
  padding: 0 20px !important;
}
</style>
